import FlipWordsComp from "./Components/FlipWord";
import DynamicAgentResponses from "./Components/RagResponse";
import AllRoutes from "./Components/Routes";




const App = () => {
  return (
    <div>
    {/* <DynamicAgentResponses/> */}
    <AllRoutes/>
    </div>
  )
};

export default App;

