
export const data = [
    {
      title: "Story",
      description:
        "Generate a story from the given prompt. Generate a story from the given prompt.Generate a story from the given prompt.",
    },
    {
      title: "Adventure",
      description:
        "Create an adventure story based on your input. Create an adventure story based on your input.",
    },
    {
      title: "Adventure",
      description:
        "Create an adventure story Ai.",
    },
  ];